import {Button, useDisclose} from 'native-base';
import React from 'react';
import SettingsRow from '@ui/components/settings/SettingsRow';
import YesNoDialog from '@ui/components/YesNoDialog';
import {useRouter} from 'solito/router';
import useTrackButtonClick from '@ui/helpers/analytics/analytics-hooks';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

const LogOutSettingsRow = () => {
    const {isLoggedIn, isLogOutLoading, logOut} = useUser();
    const {isOpen, onClose, onOpen} = useDisclose();
    const {push} = useRouter();
    const {t} = useTranslation();
    const trackButtonClicked = useTrackButtonClick();

    const onPress = () => {
        requestAnimationFrame(onOpen);
    };

    if(!isLoggedIn){
        return null;
    }

    const onLogOut = async() => {
        push('/competitions');

        await logOut();
    };

    return (
        <>
            <SettingsRow label={t('auth.logout.title')} showChevron={false} _text={{color: 'red.500'}} onPress={onPress} _row={{p:'4'}} />

            <YesNoDialog
                isOpen={isOpen}
                onClose={onClose}
                onAction={trackButtonClicked('Logged Out', {}, onLogOut)}
                isLoading={isLogOutLoading}
                title={t('auth.logout.title')}
                description={t('auth.logout.description')}
            />
        </>
    );
};

export const LogOutButton = () => {
    const {isLoggedIn, isLogOutLoading, logOut} = useUser();
    const {isOpen, onClose, onOpen} = useDisclose();
    const {t} = useTranslation();

    const onPress = () => {
        requestAnimationFrame(onOpen);
    };

    if(!isLoggedIn){
        return null;
    }

    const onLogOut = async() => {
        await logOut();
    };

    return (
        <>
            <Button variant='white' onPress={onPress} _text={{color: 'red.500'}} w='full'>
                {t('auth.logout.title')}
            </Button>

            <YesNoDialog
                isOpen={isOpen}
                onClose={onClose}
                onAction={onLogOut}
                isLoading={isLogOutLoading}
                title={t('auth.logout.title')}
                description={t('auth.logout.description')}
            />
        </>
    );

};

export default LogOutSettingsRow;
