import {Column, Text, useDisclose} from 'native-base';
import DatePicker from '@ui/components/datepicker/DatePicker';
import {DateTimePickerProps} from '@ui/components/datepicker/DatePickerProps';
import HoverPressable from '@ui/components/buttons/HoverPressable';
import Label from '@ui/components/label/Label';
import React from 'react';
import {useTranslation} from 'react-i18next';

const BirthdayComponent = (props: DateTimePickerProps) => {
    const {t} = useTranslation();
    const {isOpen: isDateOpen, onOpen: onDateOpen, onClose: onDateClose} = useDisclose();

    const onChangeValue = (date: Date) => {
        props.onChange(date);
        onDateClose();
    };

    return (
        <Column justifyContent='flex-end'>
            <Text
                variant='medium'
            >
                {t('auth.birthday.text')}
            </Text>

            <HoverPressable onPress={onDateOpen}>
                <Label bg="blacks.100" p='2' rounded='lg'>
                    <Text variant='medium'>
                        {props.value ? props.value.toDateString() : t('general.datePicker.selectDate')}

                        <DatePicker
                            type='date'
                            {...props}
                            open={isDateOpen}
                            onClose={onDateClose}
                            onChange={onChangeValue}
                        />
                    </Text>
                </Label>
            </HoverPressable>

        </Column>
    );
};

export default BirthdayComponent;
