import {IStackProps, Stack} from 'native-base';
import {MotiPressable} from 'moti/interactions';
import React from 'react';

export interface NavigationLinkProps extends IStackProps {
    children: JSX.Element;
    hover?: number;
    onPress?: () => void;
}

const HoverPressable = (props: NavigationLinkProps) => {
    const {children, hover = 0.8, onPress, ...restProps} = props;

    return (
        <MotiPressable
            animate={({hovered, pressed}) => {
                'worklet';
                return {
                    opacity: pressed ? 0.9 : hovered ? hover : 1,
                };
            }}
            onPress={onPress}
            disabled={!onPress}
        >
            <Stack {...restProps}>
                {children}
            </Stack>
        </MotiPressable>
    );
};

export default HoverPressable;
