import {AlertDialog, Button, Row} from 'native-base';
import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';

interface DialogProps {
    isOpen: boolean;
    onClose: () => void;
    onAction?: () => void;
    title: string;
    description: string;
    danger?: boolean;
    isLoading?: boolean;
    YesButton?: React.ReactNode;
}

const YesNoDialog = (props: DialogProps) => {
    const {isOpen, onClose, onAction, title, description, danger, isLoading = false, YesButton} = props;
    const {t} = useTranslation();
    const cancelRef = useRef(null);

    return (
        <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose} closeOnOverlayClick>
            <AlertDialog.Content >
                <AlertDialog.CloseButton />

                <AlertDialog.Header >{title}</AlertDialog.Header>

                <AlertDialog.Body>
                    {description}
                </AlertDialog.Body>

                <AlertDialog.Footer>
                    <Row w='full' space='2'>
                        <Button variant='ghostPrimary' borderColor='secondary.100' borderWidth={1} flex={1} onPress={onClose} ref={cancelRef}>
                            {t('actions.cancel')}
                        </Button>

                        {YesButton ? YesButton :
                            <Button colorScheme={danger ? 'red' : 'primary'} flex={1} variant={danger ? 'solid' : 'primary'} isLoading={isLoading} onPress={onAction}>
                                {t('actions.yes')}
                            </Button>}
                    </Row>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>
    );
};

export default YesNoDialog;
