import {Box, Center, Text} from 'native-base';
import {LogOutButton} from '@ui/features/auth/components/LogOutButton';
import NavigationButton from './buttons/NavigationButton';
import React from 'react';
import {getScreen} from '@ui/provider/navigation/screens';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

interface Props {
    error?: string;
}

const NotFound = (props: Props) => {
    const {error} = props;
    const {isLoggedIn} = useUser();
    const {t} = useTranslation();

    const isPermissionError = error?.includes('permission-denied');

    return (
        <Center flex={1} p='4'>
            <Text variant='h4' textAlign='center' p='4'>
                {isPermissionError ? t('general.error.permissionDenied') : t('general.notFound')}
            </Text>

            <Box h='4'/>

            {isLoggedIn && (<LogOutButton />)}

            <Box h='4'/>

            <NavigationButton path={getScreen('clubhouse')} w='full'>
                {t('general.goHome')}
            </NavigationButton>
        </Center>
    );
};

export default NotFound;
