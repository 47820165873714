import {Box, Column, Divider, ITextProps, Row} from 'native-base';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import React from 'react';
import SettingsTitle from '@ui/components/settings/SettingsTitle';
import {isArray} from 'lodash';

interface SettingsSectionProps {
    title?: string;
    titleLeft?: JSX.Element;
    titleRight?: JSX.Element;
    _title?: ITextProps;
    children: JSX.Element | (JSX.Element | undefined)[];
    _column?: IHStackProps;
    _childrenColumn?: IHStackProps;
}

const SettingsSection = ({title, titleLeft, titleRight, children, _childrenColumn, _column, _title}: SettingsSectionProps) => (
    <Column
        bg='white'
        rounded='xl'
        py='4'
        {..._column}
    >
        {title && (
            <Row alignItems='center' pl={titleLeft !== undefined ? 4 : 0} pr='4' mb='4'>
                {titleLeft}

                <SettingsTitle text={title} _title={{..._title, flex: 1}} />

                {titleRight}
            </Row>
        )}

        {title && <Divider mx='4' mb='4'/>}

        {(isArray(children) ? children : [children]).map((child, index, array) => (
            <Column key={index} px='4' {..._childrenColumn}>
                {child}

                {index !== array.length - 1 && <Box my='2'><Divider /></Box>}
            </Column>
        ))}
    </Column>
);

export type {SettingsSectionProps};

export default SettingsSection;
