import {ITextProps, Text} from 'native-base';
import React from 'react';

interface SettingsTitleProps {
    text: string;
    _title?: ITextProps;
}

const SettingsTitle = ({text, _title}: SettingsTitleProps) => (
    <Text variant='bodySmBold' fontWeight={600} textTransform="capitalize" px='4' {..._title}>{text}</Text>
);

export default SettingsTitle;
